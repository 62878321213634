// components import

// import Main from './components/Main'
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";
import Line from "./components/Line";
// import Test from './components/Test';

// pages:
import Lang from "./components/Lang";

import Home from "./components/Pages/Home";
import Biography from "./components/Pages/Biography";
import Media from "./components/Pages/Media";
import Discography from "./components/Pages/Discography";
import Video from "./components/Pages/Video";
import Album from "./components/Pages/Album";
import Books from "./components/Pages/Books";
import Partners from "./components/Pages/Partners";
import Contact from "./components/Pages/Contact";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import Album_name from "./components/Pages/Album_name";
import Discography_name from "./components/Pages/Discography_name";
import Book_name from "./components/Pages/Book_name";

// css import
import "./css/fonts.css";
import "./css/pages/home.css";
import "./css/pages/contact.css";
import "./css/gujstrap.css";
import "./css/root.css";
import "./css/pages/biography.css";
import "./css/pages/partners.css";
import "./css/pages/media.css";
import "./css/pages/video.css";
import "./css/pages/books.css";
import "./css/code/Album_gallery.css";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import MobileFooter from "./components/MobileFooter";

function App() {
  return (
    <BrowserRouter>
      <div className="container-fluid">
        <div className="row pup">
          <Sidebar />

          {/* MAIN CONTENT */}
          <div className="col-xl-12 vh-xl-100 p-0 main-col">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/Biography" component={Biography} />
              <Route path="/Media" component={Media} />
              <Route exact path="/Discography" component={Discography} />
              <Route
                path="/Discography/Discography_name"
                component={Discography_name}
              />
              <Route path="/Video" component={Video} />
              <Route exact path="/Album" component={Album} />
              <Route path="/Album/Album_name" component={Album_name} />
              <Route exact path="/Books" component={Books} />
              <Route path="/Books" component={Book_name} />
              <Route path="/Partners" component={Partners} />
              <Route path="/Contact" component={Contact} />
            </Switch>

            <div className="max-md-none">
              <Lang />
            </div>
            <MobileFooter />
            <Line />
          </div>
        </div>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;

// FOR SVG: change in node modules > react-svg-use.js > _react2.default.createElement('use', { xlinkHref: '../icons/svg.svg#' + props.id, style: { fill: props.color } })
