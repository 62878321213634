function Partners() {
  return (
    <div className="row gutter-0">
      <div className="col-12">
        <h3 className="page-title">Partners</h3>

        <div className="partners-div">
          <a href="#partner">
            <img src="img/photos/partners/partner-1.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-2.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-3.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-4.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-5.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-6.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-7.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-8.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-1.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-2.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-3.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-4.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-5.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-6.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-7.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-8.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-1.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-2.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-3.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-4.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-5.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-6.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-7.png" alt="partner" />
          </a>

          <a href="#partner">
            <img src="img/photos/partners/partner-8.png" alt="partner" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Partners;
