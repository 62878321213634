// component import
import Bottom from "../components/Bottom";
import { NavLink } from "react-router-dom";
import Lang from "../components/Lang";
import { Route } from "react-router";

// css import
import "../css/components/sidebar.css";
import "../css/code/nav.css";

function Sidebar() {
  return (
    // SIDEBAR - NAVBAR
    <div className="col-xl-12 p-0 vh-xl-100 sidebar-div">
      <div className="sidebar-absolute-routes">
        <Route exact path="/" render={() => <p>Home</p>} />
        <Route path="/Biography" render={() => <p>Biography</p>} />
        <Route path="/Media" render={() => <p>Media</p>} />
        <Route path="/Discography" render={() => <p>Discography</p>} />
        <Route path="/Video" render={() => <p>Video</p>} />
        <Route path="/Album" render={() => <p>Photo Albums</p>} />
        <Route path="/Books" render={() => <p>Books</p>} />
        <Route path="/Partners" render={() => <p>Partners</p>} />
        <Route path="/Contact" render={() => <p>Contact</p>} />
      </div>

      {/* LOGO */}
      <div className="sidebar-logo-div close-btn">
        <NavLink exact to="/">
          <img src="../img/logo/logo.svg" alt="logo" />
        </NavLink>
      </div>

      {/* SOUND */}
      <div className="sidebar-sound-div">
        <img className="testtest" src="../img/svg/playbutton.svg" alt="icon" />
        <p>Sound off</p>
      </div>

      <div className="transparent-close"></div>

      {/* LANG & BURGER */}
      <div className="lang-burger">
        <div
          className="sidebar-gujburger-div"
          data-trigger="card_mobile"
          type="button"
        >
          <div className="menu-btn">
            <div className="menu-btn__burger"></div>
          </div>

          <div className="hidden_btn"></div>
        </div>

        <div className="responsive-red-line"></div>

        <Lang />
      </div>

      <span className="screen-darken"></span>

      {/* NAV LINKS */}
      <nav className="sidebar-navlinks-div mobile-offcanvas" id="card_mobile">
        <ul>
          <li className="close-btn">
            <NavLink exact to="/">
              Home
            </NavLink>
          </li>
          <li className="close-btn">
            <NavLink to="/Biography">Biography</NavLink>
          </li>
          <li className="close-btn">
            <NavLink to="/Media">Media News</NavLink>
          </li>
          <li className="close-btn">
            <NavLink to="/Discography">Discography</NavLink>
          </li>
          <li className="close-btn">
            <NavLink to="/Video">Video Tour</NavLink>
          </li>
          <li className="close-btn">
            <NavLink to="/Album">Photo Albums</NavLink>
          </li>
          <li className="close-btn">
            <NavLink to="/Books">Books</NavLink>
          </li>
          <li className="close-btn">
            <NavLink to="/Partners">Partners</NavLink>
          </li>
          <li className="close-btn">
            <NavLink to="/Contact">Contact</NavLink>
          </li>
          <li className="close-btn nav-beflex">
            <a target="_blank" rel="noreferrer" href="https://beflex.ge/">
              By: beflex
            </a>
          </li>
        </ul>
      </nav>

      {/* ///////////////////////////////// */}

      <Bottom />
    </div>
  );
}

export default Sidebar;
