import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";

export default class ThumbnailSlider extends React.Component {
  constructor(props) {
    super(props);

    this.primaryRef = React.createRef();
    this.secondaryRef = React.createRef();
  }

  componentDidMount() {
    this.primaryRef.current.sync(this.secondaryRef.current.splide);
  }

  render() {
    const primaryOptions = {
      type: "slide",
      perPage: 1,
      perMove: 1,
      gap: "1rem",
      pagination: false,
    };

    const secondaryOptions = {
      focus: "center",
      fixedWidth: "14.948vw",
      type: "slide",
      perPage: 3,
      rewind: true,
      pagination: false,
      cover: true,
      isNavigation: true,
      updateOnMove: true,

      breakpoints: {
        1199: {
          fixedWidth: "180px",
        },

        620: {
          fixedWidth: "27.467vw",
        },
      },
    };

    return (
      <div className="home-slider-div">
        <Splide
          className="primary-slider"
          options={primaryOptions}
          ref={this.primaryRef}
        >
          <SplideSlide>
            <img src="img/home-slider/thumb-1.jpg" alt="slide" />
          </SplideSlide>

          <SplideSlide>
            <img src="img/home-slider/thumb-2.jpg" alt="slide" />
          </SplideSlide>

          <SplideSlide>
            <img src="img/home-slider/thumb-1.jpg" alt="slide" />
          </SplideSlide>

          <SplideSlide>
            <img src="img/home-slider/thumb-3.jpg" alt="slide" />
          </SplideSlide>

          <SplideSlide>
            <img src="img/home-slider/thumb-2.jpg" alt="slide" />
          </SplideSlide>
        </Splide>

        <Splide
          className="secondary-slider"
          options={secondaryOptions}
          ref={this.secondaryRef}
        >
          <SplideSlide className="is-visible">
            <img src="img/home-slider/thumb-1.jpg" alt="slide" />
          </SplideSlide>

          <SplideSlide>
            <img src="img/home-slider/thumb-2.jpg" alt="slide" />
          </SplideSlide>

          <SplideSlide>
            <img src="img/home-slider/thumb-1.jpg" alt="slide" />
          </SplideSlide>

          <SplideSlide>
            <img src="img/home-slider/thumb-3.jpg" alt="slide" />
          </SplideSlide>

          <SplideSlide>
            <img src="img/home-slider/thumb-2.jpg" alt="slide" />
          </SplideSlide>
        </Splide>
      </div>
    );
  }
}
