// css import
import "../css/components/bottom.css";

// react import
import Icon from "react-svg-use";

function Bottom() {
  return (
    <div className="sidebar-bottom-section">
      {/* BOTTOM DIV */}
      <div className="sidebar-bottom-div">
        {/* MONO LOGO */}
        <img src="../img/logo/mono-logo.png" alt="img" />

        {/* ICONS */}
        <div className="sidebar-bottom-icons">
          <a href="#1">
            <Icon id="yt" />
          </a>

          <a href="#1">
            <Icon id="fb" />
          </a>

          <a href="#1">
            <Icon id="ig" />
          </a>

          <a href="#1">
            <Icon id="tw" />
          </a>

          <a href="#1">
            <Icon id="vm" />
          </a>
        </div>

        <a className="mobile-footer-text" href="#link">
          By: DesignGroup
        </a>

        {/* COPYRIGHT */}
        <div className="sidebar-bottom-copyright">
          <p>
            © <span>{new Date().getFullYear()}</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Bottom;
