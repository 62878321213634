function Biography() {
  return (
    <div className="row bio-swap">
      <div className="col-12 col-xl-6 p-0">
        <div className="biography-photo-div">
          <img src="img/photos/biography/biography.png" alt="biography" />
        </div>
      </div>

      <div className="col-12 col-xl-6 p-0">
        <h3 className="page-title">Biography</h3>

        <div className="biography-text-div">
          <p>
            Paata Beridze was born on September 10,1988 in Tbilisi,Georgia.Since
            2004 he has been playing trumpet in Leri Chechelashvili’s group at
            theV.Sarajishvili Tbilisi State Conservatoire’s experimental music
            collage.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            In 2005-2008 hestudied at theZ.Paliashvili N 1 Central Musical
            School.Simultaneously he studied at Tbilisi Music College,the
            faculty of Arts.He is awarded with specialized diploma in wind
            instruments and concertmaster.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            In 2008 he began study at the V.Sarajishvili Tbilisi State
            Conservatoire,the faculty of Orchestral Winds and
            Percussion,-specialization trumpet.&nbsp;Since this period he has
            been actively involved in various competitions and festivals.He has
            played as first trumpet with the Orchestra of Conservatoire.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            In 2010 he participated in the master courses of“Winter Academy’’in
            Tchiatura,where he successfully performed Shostakovich Piano Concert
            N 1.He also has taken part in workshops held by Guido
            Segers,Wolfgang Gugenberger,Wolfgang Haag,Frits Damrow,Frøydis Ree
            Wekre and Mauro Maur.&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>
            In 2012 he was selected to be the concertmaster of the
            festival‘’Melodies of Peace’’which was open throughout the Caucasus
            and the participant of the international festival“Travelling
            Notes”he played within the project Baroque Week and together with
            Revaz Kiknadze took part in the activity of improvising the modern
            classic of Jonas Kohler,Maximilian Marcoll&nbsp;
          </p>
          <p>
            <br />
          </p>
          <p>and Ernesto Molinari.&nbsp;</p>
          <p>
            In 2013 he was awarded by the Diploma of Laureate for the best
            performing mastership.
          </p>
          <p>
            Apart from creative activities,he was an active member of the
            Faculty Committee of V.Sarajishvili Tbilisi State Conservatoire and
            Student Self Government.&nbsp;He also worked as a PR manager
            in“Simphonieta of Georgia’’–Tbilisi State Chamber Orchestra.
          </p>
          <p>
            In 2013 Paata Beridze has established Jazz Band.In the same year he
            has formed“The Sound theatre”with Anri Ghudushauri,which offers a
            new genre of music and theatrical performances to its viewers.
          </p>
          <p>
            In 2013 he has become the face of the“Schiller”(German Trumpet
            Company)as one of the successful consumer of“Schiller”instruments
            beyond the borders.
          </p>
          <p>
            <br />
          </p>
          <p>
            In 2014 he was invited to the international festival“From Easter to
            Ascension”where he performed Mozart’s“Horn Concerto No.1”.In the
            same year he played at the Batumi VI international festival“Night
            Serenades”organized by Liana Isakadze.
          </p>
          <p>
            <br />
          </p>
          <p>
            He regularly participates in various classical and jazz concerts and
            systematically gives solo performances which are exceptional for
            their original repertoire–covering the wide range of epochs.He
            actively plays with diverse orchestras of Georgia as well.
          </p>
          <p>
            <br />
          </p>
          <p>
            He works on different CD recordings,which differ from one another
            with their nature of the genre.In 2015,together with the composer
            and pianist Zurab Khutsishvili,he had recorded a CD,which is
            intended for cinema music.
          </p>
          <p>
            <br />
          </p>
          <p>
            Since 2012,he was invited to work in“The Marjanishvili
            Theater”and“The Royal District Theatre”to accompany spectacles with
            music.Since 2014 he is a member of an ensemble which plays the
            Jewish music at“The Marjanishvili Theater”.
          </p>
          <p>
            <br />
          </p>
          <p>
            He is a member of the ensemble“Consilia”.With his initiative numbers
            of concerts are systematically played.Since 2015,the ensemble makes
            musical accompany for“The Movement Theatre”.
          </p>
          <p>
            <br />
          </p>
          <p>
            In 2014,he was delivered a lecture at the Tbilisi State
            Conservatory,where he introduced the specifics of trumpet to
            beginner composers and other musicians.
          </p>
          <p>
            <br />
          </p>
          <p>
            Paata Beridze works as a trumpet teacher at the musical school.His
            many students have been played with symphonic orchestra.
          </p>
          <p>
            <br />
          </p>
          <p>2</p>
          <p>
            <br />
          </p>
          <p>
            Under his initiative charity concerts are organized for homeless
            children.
          </p>
          <p>
            <br />
          </p>
          <p>
            In 2014,the documentary film was made about Paata Beridze,in which
            his creative life is reflected.
          </p>
          <p>
            <br />
          </p>
          <p>
            He is outstanding with his translation activities.In 2013 and 2014
            he has translated Herbert Lincoln Clarke’s"Technical Exercises"and
            Fritz Damrow’s"Fitness for Br/ass"into Georgian.The presentation
            of“The Fitness for Br/ass”was held together with the author of the
            book at the Tbilisi State Conservatory.
          </p>
          <p>
            <br />
          </p>
          <p>
            In recent years Paata Beridze was intensely collaborated with the
            radio“MUZA”.
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default Biography;
