import React from "react";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    alert("გაიგზავნა: " + this.state.value);
    event.preventDefault();
  }

  render() {
    return (
      <div className="fluid-container">
        <h3 className="page-title">Contact</h3>
        <div className="row contact-row">
          <div className="col-xl-4 col-lg-5 col-md-6 col-12">
            {/* FORM */}
            <form className="contact-form" onSubmit={this.handleSubmit}>
              <label>
                <h6>Name</h6>
                <input type="text" name="name" placeholder="Your Name" />
              </label>

              <label>
                <h6>E-Mail</h6>
                <input type="text" name="name" placeholder="Your Name" />
              </label>

              <label>
                <h6>Text area</h6>
                <textarea
                  placeholder="Your Message"
                  className="contact-textarea"
                  value={this.state.value}
                  onChange={this.handleChange}
                />
              </label>

              <button type="submit">Send Message</button>
            </form>
          </div>

          <div className="col-xl-3 col-lg-4 col-md-6 col-12">
            {/* LINKS */}
            <div className="contact-links-div">
              <h6>Links</h6>

              <div className="contact-links">
                <a href="mailto:paataberidze@gmail.com">
                  paataberidze@gmail.com
                </a>

                <a href="mailto:contact@paataberidze.com">
                  contact@paataberidze.com
                </a>

                <a href="mailto:paataberidze@gmail.com">
                  paataberidze@gmail.com
                </a>
              </div>
            </div>

            {/* DESCRIPTION */}
            <div className="contact-description-div">
              <h6>Description</h6>
              <div className="contact-description">
                <p>
                  delivered a lecture at the Tbilisi State Conservatory, where
                  he introduced the specifics of trumpet to beginner composers
                  and other musicianswhere he introduced the specifics of
                  trumpcomposers and other musicians
                </p>
                <br />
                <p>
                  delivered a lecture at the Tbilisi State Conservatory, where
                  he introduced the specifics of trumpet to beginner composers
                  and other musicianswhere he introduced the specifics of
                  trumpcomposers and other musicians
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* COVER */}
        <div className="contact-cover">
          <img src="img/photos/contact/contact-cover.png" alt="cover" />
        </div>
      </div>
    );
  }
}

export default Contact;
