// css import
import '../css/components/line.css';

function Line() {
    return (
        <div className="lonely-line"></div>
    )
}

export default Line
