import React from "react";
import { NavLink } from "react-router-dom";

function Book_name() {
  return (
    <div className="fluid-container">
      <h3 className="page-title">books</h3>

      <div className="book-content-div">
        <NavLink exact to="/Books" className="go-back">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.367"
            height="11.922"
            viewBox="0 0 7.367 11.922"
          >
            <g id="down-chevron" transform="translate(0 11.922) rotate(-90)">
              <g id="Group_5" data-name="Group 5">
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M10.516,48.907,5.961,53.462,1.406,48.907,0,50.313l5.961,5.961,5.961-5.961Z"
                  transform="translate(0 -48.907)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>

          <p>go back</p>
        </NavLink>

        <div className="book-content">
          <div className="book-content-img">
            <img src="../img/book-cover/photo-1.png" alt="book-cover" />
          </div>

          <div className="book-d-flex">
            <div className="book-inner-content">
              <h6>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.858"
                  height="28"
                  viewBox="0 0 31.858 28"
                >
                  <g
                    id="shopping-cart_1_"
                    data-name="shopping-cart(1)"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_2946"
                      data-name="Path 2946"
                      d="M10.264,18.667H27.191a.933.933,0,0,0,.9-.677L31.822,4.923a.933.933,0,0,0-.9-1.19H8.112l-.667-3A.934.934,0,0,0,6.533,0H.933a.933.933,0,0,0,0,1.867H5.785l3.37,15.164A2.8,2.8,0,0,0,10.267,22.4H27.191a.933.933,0,1,0,0-1.867H10.267a.933.933,0,0,1,0-1.866ZM29.687,5.6l-3.2,11.2H11.015L8.526,5.6Zm0,0"
                      fill="#992500"
                    />
                    <path
                      id="Path_2947"
                      data-name="Path 2947"
                      d="M150,362.8a2.8,2.8,0,1,0,2.8-2.8A2.8,2.8,0,0,0,150,362.8Zm2.8-.933a.933.933,0,1,1-.933.933A.935.935,0,0,1,152.8,361.867Zm0,0"
                      transform="translate(-140.667 -337.6)"
                      fill="#992500"
                    />
                    <path
                      id="Path_2948"
                      data-name="Path 2948"
                      d="M362,362.8a2.8,2.8,0,1,0,2.8-2.8A2.8,2.8,0,0,0,362,362.8Zm2.8-.933a.933.933,0,1,1-.933.933A.935.935,0,0,1,364.8,361.867Zm0,0"
                      transform="translate(-339.476 -337.6)"
                      fill="#992500"
                    />
                  </g>
                </svg>
                Shopping info
              </h6>

              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt{" "}
              </p>
            </div>

            <div className="book-inner-content">
              <h6>Shopping info</h6>

              <ul>
                <li>another Pieces , weitere Stücke</li>
                <li>A. Goedicke - Concert etude</li>
                <li>B. BRITTEN - PETER'S DREAMS,</li>
                <li>B. BRITTEN ELLEN'S ARIA</li>
                <li>B. BRITTEN FISH IN THE UNRUFFLED LAKES</li>
                <li>B. BRITTEN PROUD SONGSTERS</li>
                <li>J.Barat - Andante et Scherzo</li>
                <li>T.Takemitsu: Paths</li>
                <li>1. SHOLOKOVI 2 3</li>
                <li>2. SHOSTAKOVICH</li>
                <li>3. SHUBERTI</li>
                <li>4. shuman</li>
                <li>5. SKARLATI</li>
                <li>6. SKRIABIN</li>
                <li>7. TAKEMITSHU</li>
                <li>8. TCHAIKOVSKI</li>
                <li>9. VIVALDI</li>
                <li>10. AB_Tartini_ConcertoD53_piccinA</li>
                <li>ami dunker</li>
                <li>11. tchaikovski neapolitanuri cekva</li>
                <li>12. bach ave maria</li>
                <li>13. shostakovich aria</li>
                <li>14. tarantela</li>
                <li>15. KRUMFERI variaciebi</li>
                <li>16. lela trumpet Es</li>
                <li>17. gordeli vals</li>
                <li>18. ARAYISHVILI ROMANCE</li>
                <li>19. MACHAVARIANI ROMANCE - AR DAIDARDO</li>
                <li>20. SHOSTAKOVICHI ROMANCE</li>
                <li>21. gabunia sonata</li>
                <li>22. arenski</li>
                <li>23. l.mocarti - koncerti sayvirisaTvis (re-maJ.)</li>
                <li>24. brandt 2</li>
                <li>25. n.rimski-korsakovi</li>
                <li>26. v.Solokovi - koncerti #2 sayvirisaTvis orkestrTan</li>
                <li>27. f.depre koncertino (si-bemol maJ.)</li>
                <li>28. J.bara andante da skerco</li>
              </ul>
            </div>
          </div>

          {/* ... */}
        </div>
      </div>
    </div>
  );
}

export default Book_name;
