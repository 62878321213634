import React from "react";
import Bottom from "./Bottom";

function MobileFooter() {
  return (
    <div className="mobile-footer">
      <div className="mobile-footer-mails">
        <a href="#mail1">paataberidze@gmail.com</a>

        <a href="#mail2">contact@paataberidze.com</a>
      </div>

      <Bottom />

      <div className="mobile-footer-content">
        <p>Paata Beridze</p>

        <img src="../img/logo/trumpet.png" alt="paata beridze" />
      </div>
    </div>
  );
}

export default MobileFooter;
