import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";

function Media() {
  return (
    <div className="fluid-container">
      <h3 className="page-title">Media News</h3>

      <div className="media-slider-div media-gkfall">
        <Splide
          className="media-slider"
          options={{
            rewind: true,
            isNavigation: true,
            pagination: false,
            updateOnMove: true,
          }}
        >
          <SplideSlide>
            <div
              data-bs-toggle="modal"
              data-bs-target="#mediaModal"
              className="media-img"
            >
              <img src="img/media-slider/thumb-2.png" alt="media" />
            </div>
            <h6>Bach Masterwork</h6>
          </SplideSlide>
          <SplideSlide>
            <div className="media-img">
              <img src="img/media-slider/thumb-1.png" alt="media" />
            </div>
            <h6>Trumpet Solo Concert</h6>
          </SplideSlide>
          <SplideSlide>
            <div className="media-img">
              <img src="img/media-slider/thumb-2.png" alt="media" />
            </div>
            <h6>Bach Masterwork</h6>
          </SplideSlide>
          <SplideSlide>
            <div className="media-img">
              <img src="img/media-slider/thumb-3.png" alt="media" />
            </div>
            <h6>Concervatoire</h6>
          </SplideSlide>
          <SplideSlide>
            <div className="media-img">
              <img src="img/media-slider/thumb-4.png" alt="media" />
            </div>
            <h6>Landmark</h6>
          </SplideSlide>
          <SplideSlide>
            <div className="media-img">
              <img src="img/media-slider/thumb-1.png" alt="media" />
            </div>
            <h6>Trumpet Solo Concert</h6>
          </SplideSlide>
          <SplideSlide>
            <div className="media-img">
              <img src="img/media-slider/thumb-2.png" alt="media" />
            </div>
            <h6>Bach Masterwork</h6>
          </SplideSlide>
          <SplideSlide>
            <div className="media-img">
              <img src="img/media-slider/thumb-3.png" alt="media" />
            </div>
            <h6>Concervatoire</h6>
          </SplideSlide>
          <SplideSlide>
            <div className="media-img">
              <img src="img/media-slider/thumb-4.png" alt="media" />
            </div>
            <h6>Landmark</h6>
          </SplideSlide>
        </Splide>
      </div>
      <div
        className="modal fade"
        id="mediaModal"
        tabindex="-1"
        aria-labelledby="mediaModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="media-modal-body">
              <img src="img/media-slider/thumb-1.png" alt="media" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Media;
