// css import
import "../css/components/lang.css";

function Lang() {
  return (
    <div>
      <div className="main-lang-div">
        <div className="lang-div active-lang-div">
          <div className="lang-icon-div">
            <img
              className="lang-icon"
              src="../img/svg/lang-icon.svg"
              alt="icon"
            />
          </div>

          <p className="active-lang">eng</p>

          <div className="lang-arrow-div">
            <img
              className="lang-arrow"
              src="../img/svg/lang-arrow.svg"
              alt="icon"
            />
          </div>
        </div>

        <div className="lang-div">
          <p>eng</p>
        </div>
        <div className="lang-div">
          <p>eng</p>
        </div>
      </div>
    </div>
  );
}

export default Lang;
