// css import
import "../css/components/footer.css";

function Footer() {
  return (
    <div>
      <footer>
        {/* text and icon: */}
        <p className="footer-paata-beridze">Paata Beridze</p>
        <img
          className="footer-img"
          src="../img/logo/trumpet.png"
          alt="paata beridze"
        />

        {/* footer links: */}
        <div className="footer-links-div">
          <a className="footer-link" href="mailto:paataberidze@gmail.com">
            paataberidze@gmail.com
          </a>
          <a className="footer-link" href="mailto:contact@paataberidze.com">
            contact@paataberidze.com
          </a>
          <a className="footer-link footer-creator" href="#1" target="_blank">
            By: <span>DesignGroup</span>
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
