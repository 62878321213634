import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";
import { NavLink } from "react-router-dom";

function Books() {
  return (
    <div className="fluid-container">
      <h3 className="page-title">books</h3>

      <div className="video-slider-div">
        <Splide
          className="media-slider video-slider"
          options={{
            type: "loop",
            breakpoints: {
              1199: {
                type: "slide",
              },
            },
            perpage: 3,
            permove: 1,
            rewind: true,
            isNavigation: true,
            pagination: false,
            updateOnMove: true,
          }}
        >
          <SplideSlide>
            <div>
              <img src="img/books-slider/slide-1.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics of trumpet to beginner composers and
              other musicianswhere he introduced the specifics of trumpcomposers
              and other musicians
            </p>

            <NavLink to="/Books/Book_name" className="b_slide__button">
              view books
            </NavLink>
          </SplideSlide>

          <SplideSlide>
            <div>
              <img src="img/books-slider/slide-2.png" alt="no yt" />
            </div>
            <h3 className="slide__h3">Video Description</h3>
            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics of trumpet to beginner composers and
              other musicianswhere he introduced the specifics of trumpcomposers
              and other musicians
            </p>
            <NavLink to="/Books/Book_name" className="b_slide__button">
              view books
            </NavLink>{" "}
          </SplideSlide>

          <SplideSlide>
            <div>
              <img src="img/books-slider/slide-3.png" alt="no yt" />
            </div>
            <h3 className="slide__h3">Video Description</h3>
            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics of trumpet to beginner composers and
              other musicianswhere he introduced the specifics of trumpcomposers
              and other musicians
            </p>
            <NavLink to="/Books/Book_name" className="b_slide__button">
              view books
            </NavLink>{" "}
          </SplideSlide>

          <SplideSlide>
            <div>
              <img src="img/books-slider/slide-2.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics of trumpet to beginner composers and
              other musicianswhere he introduced the specifics of trumpcomposers
              and other musicians
            </p>

            <NavLink to="/Books/Book_name" className="b_slide__button">
              view books
            </NavLink>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default Books;
