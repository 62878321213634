import React from "react";
import { NavLink } from "react-router-dom";

function Discography_name() {
  return (
    <div className="fluid-container">
      <h3 className="page-title">books</h3>

      <div className="book-content-div">
        <NavLink exact to="/Discography" className="go-back">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="7.367"
            height="11.922"
            viewBox="0 0 7.367 11.922"
          >
            <g id="down-chevron" transform="translate(0 11.922) rotate(-90)">
              <g id="Group_5" data-name="Group 5">
                <path
                  id="Path_6"
                  data-name="Path 6"
                  d="M10.516,48.907,5.961,53.462,1.406,48.907,0,50.313l5.961,5.961,5.961-5.961Z"
                  transform="translate(0 -48.907)"
                  fill="#fff"
                />
              </g>
            </g>
          </svg>

          <p>go back</p>
        </NavLink>

        <div className="book-content">
          <div className="book-content-img">
            <img
              src="../img/discography-cover/photo-1.png"
              alt="discography-cover"
            />
          </div>

          <div className="book-d-flex discography">
            <div className="book-inner-content">
              <h6 className="text-uppercase">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="31.858"
                  height="28"
                  viewBox="0 0 31.858 28"
                >
                  <g
                    id="shopping-cart_1_"
                    data-name="shopping-cart(1)"
                    transform="translate(0 0)"
                  >
                    <path
                      id="Path_2946"
                      data-name="Path 2946"
                      d="M10.264,18.667H27.191a.933.933,0,0,0,.9-.677L31.822,4.923a.933.933,0,0,0-.9-1.19H8.112l-.667-3A.934.934,0,0,0,6.533,0H.933a.933.933,0,0,0,0,1.867H5.785l3.37,15.164A2.8,2.8,0,0,0,10.267,22.4H27.191a.933.933,0,1,0,0-1.867H10.267a.933.933,0,0,1,0-1.866ZM29.687,5.6l-3.2,11.2H11.015L8.526,5.6Zm0,0"
                      fill="#992500"
                    />
                    <path
                      id="Path_2947"
                      data-name="Path 2947"
                      d="M150,362.8a2.8,2.8,0,1,0,2.8-2.8A2.8,2.8,0,0,0,150,362.8Zm2.8-.933a.933.933,0,1,1-.933.933A.935.935,0,0,1,152.8,361.867Zm0,0"
                      transform="translate(-140.667 -337.6)"
                      fill="#992500"
                    />
                    <path
                      id="Path_2948"
                      data-name="Path 2948"
                      d="M362,362.8a2.8,2.8,0,1,0,2.8-2.8A2.8,2.8,0,0,0,362,362.8Zm2.8-.933a.933.933,0,1,1-.933.933A.935.935,0,0,1,364.8,361.867Zm0,0"
                      transform="translate(-339.476 -337.6)"
                      fill="#992500"
                    />
                  </g>
                </svg>
                Jamais Vu
              </h6>

              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt{" "}
              </p>

              <h6>Description</h6>

              <p>EAN: 0845221072852</p>
              <p>Veröffentlichung: 2018</p>
              <br />
              <p>Verfügbar auf jpc und amazon.</p>
            </div>

            <div className="book-inner-content">
              <h6>Shopping info</h6>

              <ul>
                <li>1: Tribute to Old England</li>
                <li>2: Italian Trumpet Concertos</li>
                <li>3: German Trumpet Concertos</li>
                <li>4: Baroque Trumpet Concertos</li>
                <li>5: Classical Trumpet Concertos</li>
                <li>6: 20th Century Trumpet Concertos</li>
                <li>7: Contemporary Trumpet Concertos</li>
                <li>8: Trumpet and Organ</li>
                <li>9: La Belle Epoque &ndash; Trumpet and Piano</li>
                <li>10: Trumpet and Percussion</li>
              </ul>
              <br />
              <p>Label: Capriccio</p>
              <p>EAN: 0845221072852</p>
              <p>Veröffentlichung: 2018</p>
              <br />
              <p>Verfügbar auf jpc und amazon.</p>
            </div>
          </div>

          {/* ... */}
        </div>
      </div>
    </div>
  );
}

export default Discography_name;
