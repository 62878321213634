import { Splide, SplideSlide } from "@splidejs/react-splide";
import React from "react";

function Video() {
  return (
    <div className="fluid-container">
      <h3 className="page-title">Video Tour</h3>

      <div className="video-slider-div">
        <Splide
          className="media-slider video-slider"
          options={{
            type: "loop",
            breakpoints: {
              1199: {
                type: "slide",
              },
            },
            perpage: 3,
            permove: 1,
            rewind: true,
            isNavigation: true,
            pagination: false,
            updateOnMove: true,
          }}
        >
          <SplideSlide>
            <div>
              <img src="img/video-slider/no-yt.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics of trumpet to beginner composers and
              other musicianswhere he introduced the specifics of trumpcomposers
              and other musicians
            </p>

            <button className="v_slide__button">
              <img src="img/video-slider/mono1.png" alt="icon" />
            </button>
          </SplideSlide>
          <SplideSlide>
            <div>
              <img src="img/video-slider/no-yt.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced
            </p>

            <button className="v_slide__button">
              <img src="img/video-slider/mono2.png" alt="icon" />
            </button>
          </SplideSlide>
          <SplideSlide>
            <div>
              <img src="img/video-slider/no-yt.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the
            </p>

            <button className="v_slide__button">
              <img src="img/video-slider/mono1.png" alt="icon" />
            </button>
          </SplideSlide>
          <SplideSlide>
            <div>
              <img src="img/video-slider/no-yt2.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics of trumpet to beginner composers and
              other musicianswhere he introduced the specifics of trumpcomposers
              and other musicians
            </p>

            <button className="v_slide__button">
              <img src="img/video-slider/mono2.png" alt="icon" />
            </button>
          </SplideSlide>
          <SplideSlide>
            <div>
              <img src="img/video-slider/no-yt2.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics
            </p>

            <button className="v_slide__button">
              <img src="img/video-slider/mono1.png" alt="icon" />
            </button>
          </SplideSlide>
          <SplideSlide>
            <div>
              <img src="img/video-slider/no-yt2.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics of trumpet to beginner composers and
              other musicianswhere he introduced the specifics of trumpcomposers
              and other musicians
            </p>

            <button className="v_slide__button">
              <img src="img/video-slider/mono2.png" alt="icon" />
            </button>
          </SplideSlide>
          <SplideSlide>
            <div>
              <img src="img/video-slider/no-yt.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics
            </p>

            <button className="v_slide__button">
              <img src="img/video-slider/mono1.png" alt="icon" />
            </button>
          </SplideSlide>
          <SplideSlide>
            <div>
              <img src="img/video-slider/no-yt.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics of trumpet to beginner composers and
              other musicianswhere he introduced the specifics of trumpcomposers
              and other musicians
            </p>

            <button className="v_slide__button">
              <img src="img/video-slider/mono2.png" alt="icon" />
            </button>
          </SplideSlide>
          <SplideSlide>
            <div>
              <img src="img/video-slider/no-yt.png" alt="no yt" />
            </div>

            <h3 className="slide__h3">Video Description</h3>

            <p className="slide__p">
              delivered a lecture at the Tbilisi State Conservatory, where he
              introduced the specifics of trumpet to beginner composers and
              other musicianswhere he introduced the specifics of trumpcomposers
              and other musicians
            </p>

            <button className="v_slide__button">
              <img src="img/video-slider/mono1.png" alt="icon" />
            </button>
          </SplideSlide>
        </Splide>
      </div>
    </div>
  );
}

export default Video;
